import { useApp } from '@/utils/useapp';

import React, { useEffect, useMemo, useState } from 'react';
import { Table, message, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CntrAndMblColumnRender from '@/components/CntrAndMblColumnRender';
import get from 'lodash/get';
import { Memo } from '@/pages/dispatchs/components/Memo';
import { Attributes } from '@/components/ContainerTable/components/ContainerTable';
import { OrderIdColumn } from '@/pages/orders/components/OrderIdColumn';
import { TerminalRedirectLink } from '@/components/TerminalRedirectLink';
import { RAMP_PORT } from '@/pages/dispatchs/components/data';
import { RateLink } from '@/components/Rate/RateLink';
import { OPEN_TYPE_SELL_RATE } from '@/components/Rate/OpenBuyRateOrSellRateDrawer';

const RenderRate: React.FC<{ rate: number; probability?: number }> = ({
  rate,
  probability,
}) => {
  if (probability) {
    return (
      <>
        {rate} ({probability}%)
      </>
    );
  } else {
    return <>{rate}</>;
  }
};

export type TContainerTable = {
  onSave: (containerId: number) => void;
  container: any;
};

const ContainerRow: React.FC<TContainerTable> = ({ container, onSave }) => {
  const app = useApp();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const snapshot = useMemo(() => get(container, 'snapshot', {}), [container]);

  const getPossibleCharge = (
    code: string,
  ):
    | { id: number; code: string; rate: number; probability: number }
    | undefined
    | null => {
    if (!snapshot) {
      return null;
    }
    return get(snapshot, 'possible_charges')?.find((p) => p.code == code);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Order ID',
      dataIndex: ['order', 'uid'],
      key: 'order_id',
      width: 80,
      render: (text, record) => (
        <OrderIdColumn
          record={record.order}
          container={record}
          showUrgent={true}
          showFileSearch={false}
          refreshOrder={() => onSave(record.id)}
        />
      ),
    },
    {
      title: () => <>CNTR# / MBL</>,
      dataIndex: 'number',
      key: 'number',
      width: 230,
      render: (text, record) => (
        <CntrAndMblColumnRender container={record} order={record.order} />
      ),
    },
    {
      title: 'Customer',
      dataIndex: ['user', 'name'],
      width: 100,
    },
    {
      title: 'IR ETA',
      dataIndex: 'final_port_eta',
      key: 'final_port_eta',
      width: 100,
    },
    {
      title: 'Truck',
      dataIndex: 'truck',
      key: 'truck',
      width: 140,
      render: (text, record) => {
        return (
          <RenderRate
            rate={get(record, 'sell_rate.base_rate')}
            probability={100}
          />
        );
      },
    },
    {
      title: 'Chassis',
      dataIndex: 'chassis',
      key: 'chassis',
      width: 100,
      render: (text, record) => {
        const possibleCharge = getPossibleCharge('CHAFE');
        if (possibleCharge) {
          return (
            <RenderRate
              rate={possibleCharge.rate}
              probability={possibleCharge.probability}
            />
          );
        }

        return '-';
      },
    },
    {
      title: 'Prepull',
      width: 100,
      render: (text, record) => {
        const possibleCharge = getPossibleCharge('PRECH');
        if (possibleCharge) {
          return (
            <RenderRate
              rate={possibleCharge.rate}
              probability={possibleCharge.probability}
            />
          );
        }

        return '-';
      },
    },
    {
      title: 'Yard Storage',
      width: 100,
      render: (text, record) => {
        const possibleCharge = getPossibleCharge('YARST');
        if (possibleCharge) {
          return (
            <RenderRate
              rate={possibleCharge.rate}
              probability={possibleCharge.probability}
            />
          );
        }

        return '-';
      },
    },
    {
      title: 'Drop Fee',
      width: 100,
      dataIndex: 'drop_fee',
      render: (text, record) => {
        const possibleCharge = getPossibleCharge('DROPF');
        if (possibleCharge) {
          return (
            <RenderRate
              rate={possibleCharge.rate}
              probability={possibleCharge.probability}
            />
          );
        }

        return '-';
      },
    },
    {
      title: 'Chassis Split',
      width: 110,
      render: (text, record) => {
        const possibleCharge = getPossibleCharge('CHASP');
        if (possibleCharge) {
          return (
            <RenderRate
              rate={possibleCharge.rate}
              probability={possibleCharge.probability}
            />
          );
        }

        return '-';
      },
    },
    {
      title: 'Total',
      dataIndex: 'total_rate',
      key: 'total_rate',
      width: 140,
      render: (text, record) => {
        const totalRate = get(record, 'sell_rate.total_rate');
        if (totalRate) {
          return <RenderRate rate={totalRate} />;
        }

        return '-';
      },
    },
    {
      title: 'Scen1',
      dataIndex: 'best_scenario_total_amount',
      key: 'best_scenario_total_amount',
      width: 150,
      render: (text, record) => {
        const bestScenario = snapshot?.scenarios?.find((s) =>
          s.name.startsWith('Best Scenario'),
        );
        if (bestScenario) {
          return <RenderRate rate={bestScenario.total_rate} />;
        }

        return '-';
      },
    },
    {
      title: 'Scen2',
      dataIndex: 'best_scenario_total_amount',
      key: 'best_scenario_total_amount',
      width: 150,
      render: (text, record) => {
        const averageScenario = snapshot?.scenarios?.find((s) =>
          s.name.startsWith('Average Scenario'),
        );
        if (averageScenario) {
          return <RenderRate rate={averageScenario.total_rate} />;
        }

        return '-';
      },
    },
    {
      title: 'SR#',
      dataIndex: ['sell_rate', 'rate_uid'],
      key: 'sell_rate',
      width: 100,
      render: (text, record) => {
        console.log('record', record);
        return (
          <RateLink
            type={OPEN_TYPE_SELL_RATE}
            snapshotableId={container.id}
            snapshotableType="container"
            rateUid={get(container, 'sell_rate.rate_uid')}
            hasBanModifyFields={true}
          />
        );
      },
    },
    {
      title: 'Terminal',
      dataIndex: 'terminal',
      key: 'terminal',
      width: 300,
      render: (text, record) => {
        return (
          <TerminalRedirectLink
            id={get(record, 'terminal.id')}
            label={get(record, 'terminal.name')}
            style={{
              color:
                container.destination_type == RAMP_PORT ? 'orange' : '#1890ff',
            }}
          />
        );
      },
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
      width: 120,
      render: (text, record) => (
        <Memo
          initialValue={record.internal_memo}
          onSaved={onSave}
          container={record}
        />
      ),
    },
    {
      title: 'CNTR Size',
      dataIndex: 'type',
      key: 'type',
      width: 120,
    },
    {
      title: 'Attributes',
      dataIndex: 'attributes',
      key: 'attributes',
      width: 120,
      render: (text, record) => (
        <Attributes container={record} showNone={false} />
      ),
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      width: 220,
      render: (text, record) => (
        <>
          <div>{get(record, 'sell_rate.intermodal_region.name', '')}</div>
          <div className="mt-sm">{record.from_facility_type}</div>
        </>
      ),
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      width: 220,
      render: (text, record) => (
        <>
          <div>{get(record, 'sell_rate.to_city.full_name', '')}</div>
          <div className="mt-sm">
            {' '}
            {record.to_zipcode} {record.to_facility_type}
          </div>
        </>
      ),
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      width: 120,
      render: (text, record) =>
        get(record, 'sell_rate.rate.distance') + ' miles',
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={container ? [container] : []}
      pagination={false}
      scroll={{ x: 2000, y: 400 }}
    />
  );
};

export default ContainerRow;
